import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import { PostCallout } from '../components/PostCallout'

export const PostList = ({ location, data, pageTitle, header = null }) => {
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location}>
      <div className="container post-index">
        <SEO title={pageTitle} />

        {header}

        {posts.map(node => {
          const { title, featuredImage, date, description, tags } = node.frontmatter
          const { slug } = node.fields

          return (
            <PostCallout
              title={title}
              slug={slug}
              date={date}
              featuredImage={featuredImage}
              description={description}
              tags={tags}
              key={slug}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  fragment IndexPostFragment on MarkdownRemark {
    fields {
      slug
    }
    frontmatter {
      date(formatString: "MMMM DD, YYYY")
      title
      description
      tags
      featuredImage {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`

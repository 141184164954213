import React from 'react'
import { graphql, Link } from 'gatsby'
import { PostList } from '../components/PostList'

const TagPage = ({ data, location, pageContext }) => {
  const { tag } = pageContext
  console.log(data, pageContext)

  const header = (
    <div className="tag-page-description">
      Viewing posts tagged with&nbsp;
      <span className="post-callout-tag">{tag}</span>. &nbsp;
      <Link to="/" className="standard-link --light">
        View all posts.
      </Link>
    </div>
  )

  return <PostList data={data} pageTitle="All Posts" location={location} header={header} />
}

export default TagPage

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        ...IndexPostFragment
      }
    }
  }
`
